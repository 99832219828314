exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/Article/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-index-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---src-pages-ar-jsx": () => import("./../../../src/pages/ar.jsx" /* webpackChunkName: "component---src-pages-ar-jsx" */),
  "component---src-pages-de-jsx": () => import("./../../../src/pages/de.jsx" /* webpackChunkName: "component---src-pages-de-jsx" */),
  "component---src-pages-death-count-jsx": () => import("./../../../src/pages/death-count.jsx" /* webpackChunkName: "component---src-pages-death-count-jsx" */),
  "component---src-pages-es-jsx": () => import("./../../../src/pages/es.jsx" /* webpackChunkName: "component---src-pages-es-jsx" */),
  "component---src-pages-fr-jsx": () => import("./../../../src/pages/fr.jsx" /* webpackChunkName: "component---src-pages-fr-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-global-protest-map-jsx": () => import("./../../../src/pages/global-protest-map.jsx" /* webpackChunkName: "component---src-pages-global-protest-map-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-it-jsx": () => import("./../../../src/pages/it.jsx" /* webpackChunkName: "component---src-pages-it-jsx" */),
  "component---src-pages-kr-jsx": () => import("./../../../src/pages/kr.jsx" /* webpackChunkName: "component---src-pages-kr-jsx" */),
  "component---src-pages-nl-jsx": () => import("./../../../src/pages/nl.jsx" /* webpackChunkName: "component---src-pages-nl-jsx" */),
  "component---src-pages-qrcode-jsx": () => import("./../../../src/pages/qrcode.jsx" /* webpackChunkName: "component---src-pages-qrcode-jsx" */),
  "component---src-pages-th-jsx": () => import("./../../../src/pages/th.jsx" /* webpackChunkName: "component---src-pages-th-jsx" */),
  "component---src-pages-tr-jsx": () => import("./../../../src/pages/tr.jsx" /* webpackChunkName: "component---src-pages-tr-jsx" */)
}

